

let metaName = document.createElement('meta')
metaName.setAttribute('name', 'description')
metaName.setAttribute('content', 'Software Developer, Developing things for the web.')

let metaDescription = document.createElement('meta')
metaDescription.setAttribute('name', 'keywords')
metaDescription.setAttribute('content', 'Ross Effinger, Ross, Effinger, ross effinger, ross, effinger, ross effinger portfolio, ross effinger website')


document.head.appendChild(metaName)
document.head.appendChild(metaDescription)
document.title = 'Ross Effinger | Portfolio'